@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Roboto+Slab:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playwrite+AR:wght@100..400&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
$brand-color: #402121;
body {
  background-color: #f4dde0;
}
.header-component {
  background-color: #ffbbc4;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
  .active {
    background-color: #402121;
    transition: background-color 0.5s ease;
    color: #fffdd0 !important;
  }
  .nav-link:hover {
    border-bottom: 3px solid #402121;
    transition: border-bottom 0.5s ease;
    background-color: #402121;
    color: #fffdd0;
  }
  .buy-now:hover {
    background-color: #402121;
    color: #fffdd0;
  }
  .active:hover {
    background-color: #402121;
    color: #fffdd0;
  }

  .navbar-brand {
    font-family: "Great Vibes", cursive;
    color: $brand-color;
    font-size: 1.5em;
  }

  .nav-link {
    font-family: "Roboto Slab", serif;
    color: $brand-color;
    margin-right: 15px;
  }

  .btn-outline {
    font-family: "Roboto Slab", serif;
    color: $brand-color;
    border-color: $brand-color;
  }
}

$overlay-color: #000000e9;
$text-color: #ffffff;

.carousel .slide {
  position: relative;
  height: 500px; // adjust as needed
  background-size: cover;
  background-position: center;
}
.carousel-item {
  img {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $overlay-color;
  opacity: 0.7; // adjust opacity as needed
}

.caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $text-color;
  text-align: center;
  font-family: "Raleway", sans-serif;

  h3 {
    margin-bottom: 20px;
    font-size: 2em; // adjust as needed
  }

  p {
    margin-bottom: 20px;
  }

  .btn {
    font-family: "Roboto Slab", serif;
    background-color: $text-color;
    color: $overlay-color;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background-color: darken($text-color, 10%);
      color: lighten($overlay-color, 10%);
    }
  }
}

$primary-color: #0f0808;
$secondary-color: #ebebeb;
$text-color: #000000;
$accent-color: #000000;

.about-us {
  font-family: "Roboto Slab", serif;
  color: $primary-color;
  padding: 40px 20px;
  border-radius: 10px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  animation: fadeIn 1s ease-in-out;

  .container {
    max-width: 800px;
    margin: auto;

    h1 {
      font-size: 2.5em;
      margin-bottom: 20px;
      color: $accent-color;
    }

    h2 {
      font-size: 2em;
      margin-top: 30px;
      margin-bottom: 15px;
      border-bottom: 2px solid $primary-color;
      padding-bottom: 5px;
    }

    h3 {
      font-size: 1.5em;
      margin-top: 20px;
      margin-bottom: 10px;
      color: $accent-color;
    }

    p {
      margin-bottom: 20px;
      line-height: 1.8;
    }

    ul {
      list-style-type: disc;
      margin-left: 20px;
      margin-bottom: 20px;
      padding-left: 20px;
    }

    li {
      margin-bottom: 10px;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.products {
  .product {
    padding: 10px;
    text-align: center;
    // height: 500px;
    img {
      width: 100%;
      height: 325px;
    }
  }
}

.slick-prev:before,
.slick-next:before {
  color: black;
}
.slick-prev {
  top: 145px;
  // left: -10px;
}
.slick-next {
  top: 145px;
}

.footer {
  background-color: #fcbac3;
  color: #402121 !important;
  padding: 20px;
  text-align: center;
  font-family: "Roboto Slab", serif;
  a {
    color: #402121 !important;
    text-decoration: none;
  }
}

.our-story {
  font-family: "Roboto Slab", serif;
  padding: 20px;
  .container {
    max-width: 800px;
    margin: auto;
  }
  .art-section {
    display: flex;
    flex-direction: column;
    // gap: 20px;

    .art-item {
      // background-color: lighten($primary-color, 30%);
      // padding: 20px;
      border-radius: 8px;
      // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }
  h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: $accent-color;
  }

  h2 {
    font-size: 2em;
    margin-top: 30px;
    margin-bottom: 15px;
    border-bottom: 2px solid $primary-color;
    padding-bottom: 5px;
  }

  h3 {
    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 10px;
    color: $accent-color;
  }

  p {
    margin-bottom: 20px;
    line-height: 1.8;
  }

  ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 20px;
    padding-left: 20px;
  }

  li {
    margin-bottom: 10px;
  }
}

.products {
  padding: 20px;
  .container {
    max-width: 800px;
    margin: auto;
  }
}

.custom-modal {
  font-family: "Roboto Slab", serif;
  position: relative;
  width: 50%;
  height: auto;
  margin: auto;
  padding: 20px;
  background: #ffe2e6;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  .category {
    font-weight: bold;
  }
  @media (max-width: 768px) {
    width: 80%;
  }

  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 15px;
  }

  form {
    display: flex;
    flex-direction: column;

    div {
      margin-bottom: 10px;

      label {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
      }

      input,
      textarea {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 5px;
      }

      textarea {
        resize: vertical;
      }
    }

    button {
      align-self: flex-end;
      padding: 10px 20px;
      background: #402121;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background 0.3s;

      &:hover {
        background: #793e3e;
      }
    }
  }
}

.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
  transition: color 0.3s;

  &:hover {
    color: #000;
  }
}
